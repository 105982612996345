import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import "./global.css";

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState("dark");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "dark"));
            },
        }),
        []
    );

    const theme = createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: "#e8e8e8",
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#F3F3F3" : "#1B1B1B",
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === "light" ? "#FF9E2F" : "#FFFFFF" },
                secondary: { main: "#F35B04" },
                background: {
                    default: "#343434",
                    paper: "#343434",
                },
                divider: "#3B3B3B",
                textPrimary: mode === "light" ? "#FF9E2F" : "#FFFFFF",
                borderPrimary: mode === "light" ? "#FF9E2F" : "#FFFFFF",
                dark: { main: mode === "light" ? "#333333" : "#1B1B1B" },
                light: { main: mode === "light" ? "#F3F3F3" : "#1B1B1B" },
                tabHeaderBackground: mode === "light" ? "#EEE" : "#1B1B1B",
                optionsBackground: mode === "light" ? "#fafafa" : "#1B1B1B",
				options: mode === "light" ? "#fafafa" : "#343434",
				fontecor: mode === "light" ? "#128c7e" : "#fff",
                fancyBackground: mode === "light" ? "#fafafa" : "#1B1B1B",
				bordabox: mode === "light" ? "#eee" : "#343434",
				newmessagebox: mode === "light" ? "#eee" : "#343434",
				inputdigita: mode === "light" ? "#fff" : "#666",
				contactdrawer: mode === "light" ? "#fff" : "#666",
				announcements: mode === "light" ? "#ededed" : "#343434",
				login: mode === "light" ? "#fff" : "#1C1C1C",
				announcementspopover: mode === "light" ? "#fff" : "#343434",
				chatlist: mode === "light" ? "#eee" : "#343434",
				boxlist: mode === "light" ? "#ededed" : "#343434",
				boxchatlist: mode === "light" ? "#ededed" : "#343434",
                total: mode === "light" ? "#fff" : "#343434",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? "#FFFFFF" : "#343434",
                barraSuperior: mode === "light" ? "linear-gradient(to right, #FF9E2F, #FF9E2F , #FF9E2F)" : "#343434",
				boxticket: mode === "light" ? "#EEE" : "#343434",
				campaigntab: mode === "light" ? "#ededed" : "#343434",
				mediainput: mode === "light" ? "#ededed" : "#1c1c1c",
            },
            mode,
        },
        locale
    );

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Routes />
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
